<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div 
        class="sidebar-content todo-sidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        @click="$emit('close-left-sidebar')"
      >
        <div class="todo-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters mt-2">
              <b-list-group-item
                v-for="filter in menuItems"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :badge="filter.counter" 
                  badge-classes="bg-danger"
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import * as types from '../store/types'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
  computed: {
    ...mapGetters({
      notificationsCounter: types.NOTIFICATIONS_COUNTER
    }),
    counterNotifications: function(){
      return this.notificationsCounter?.notifications || 0
    },
    counterPendencies: function(){
      return this.notificationsCounter?.pendencies || 0
    },
    counterSolved: function(){
      return this.notificationsCounter?.solved || 0
    },
    menuItems: function(){
      return [
        { title: 'Notificações', icon: 'BellIcon', route: { name: 'notification-inbox-unread' }, counter: this.counterNotifications },
        { title: 'Pendências', icon: 'AlertOctagonIcon', route: { name: 'notification-inbox-pendencies' }, counter: this.counterPendencies },
        { title: 'Resolvidas', icon: 'CheckIcon', route: { name: 'notification-inbox-solved' }, counter: this.counterSolved }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .badge.badge-up{
    right: -1px;
  }
</style>
