<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1 mt-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area p-2 h-100"
      >
        <router-view :key="$route.fullPath" />
      </vue-perfect-scrollbar>
    </div>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <notification-inbox-left-sidebar
        :mq-shall-show-left-sidebar="mqShallShowLeftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
      
    </portal>
  </div>
</template>

<script>
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import NotificationInboxLeftSidebar from '../components/NotificationInboxLeftSidebar.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    NotificationInboxLeftSidebar,
    VuePerfectScrollbar
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      perfectScrollbarSettings,
      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@media (min-width: 769px) {
  .app-fixed-search {
    display: none !important;
  }
}
.todo-application .content-area-wrapper .content-right .app-fixed-search {
  border: none;
}
</style>
